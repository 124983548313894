/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import Lottie from 'lottie-web';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import TestimonialsSlider from 'components/TestimonialsSlider/TestimonialsSlider';
import FeatureContainer from '../components/FeatureContainer';
import TrustedBy from '../components/TrustedBy';

import NewFaq from '../components/NewFaq/NewFaq';
import Reviews from '../components/Reviews';
import FeaturesGrid from '../components/FeaturesGrid/FeaturesGrid';

import { btnOnClick } from '../utils/contentProvider/function';
import { BugTrackingFeatures } from '../utils/contentProvider';

import 'styles/usecases.scss';
import 'styles/bug-tracking.scss';
import 'styles/get-started.scss';
import { faqDatabugTracking } from '../utils/contentProvider/faqDataCommon';

const BugTracking = ({ location }) => {
  const [showVideoModal, setVideoModal] = useState(false);

  const lottieContainer = useRef();

  useEffect(() => {
    Lottie.loadAnimation({
      container: lottieContainer.current,
      path: '/assets/lotties/bug-tracking.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }, []);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }

  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector(anchor.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  return (
    <Layout
      location={location}
      showStickyBottomBanner
      hideTopBar
      productPage
      product="Bug Tracking">
      <Meta
        title="Best Bug Reporting & Tracking Software - Ruttl"
        description="Bug Reporting & Tracking tool to steamline your quality assurance process and simplify collecting feedback."
        url="https://ruttl.com/bug-tracking-tool/"
      />
      <div
        id="demo-modal"
        className={cn(['demo-modal', { show: showVideoModal }])}>
        <button
          type="button"
          className="button-unstyled"
          onClick={toggleVideoModal}>
          <img src="/assets/img/close.svg" alt="close" title="close" />
        </button>
        {showVideoModal && (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/Tz7WzxUc7tk"
            frameBorder="0"
            title="ruttl demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>

      <main className="styled-main">
        <section className="features-hero">
          <div className="container">
            <div className="hero-content">
              <div className="hero-desc">
                <div className="sub-product sub-product--pdf">
                  <img src="/assets/img/bug-outlined-icon.svg" alt="bug icon" />
                  <span>
                    Bug Tracking Tool is a sub product of Website Feedback Tool
                  </span>
                </div>
                <h1>Report and track bugs smarter together</h1>
                <p className="sub-text">
                  Fastest way to report bugs directly on live product without
                  the need of screenshots and Jira tickets.
                </p>

                <button
                  type="button"
                  className="button"
                  onClick={btnOnClick({
                    to: 'https://web.ruttl.com/',
                  })}>
                  Get started for free
                </button>
                <a
                  href="https://calendly.com/setup-demo-with-ruttl/30min"
                  target="_blank"
                  rel="noopener noreferrer"
                  type="button"
                  className="button book-demo">
                  Book a Demo
                </a>
                <p className="sub-text-small">
                  Resolve bugs with your team within minutes
                </p>
              </div>
              <div className="hero-img">
                <div ref={lottieContainer}></div>
              </div>
            </div>
          </div>
        </section>
        <TrustedBy />
        <section
          className="styled-bug-tracking"
          style={{ backgroundColor: '#fff' }}
          id="how-it-works">
          <div className="container">
            <h2 className="center" id="view-demo">
              Get started in 10 seconds
            </h2>

            <div className="flex-2 flex flex-wrap align-items-stretch">
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/add-plugin.svg"
                        alt="install snippet icon"
                        title="install snippet icon"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Add Plugin</h3>
                      <p>
                        Install the code snippet on your website and ruttl will
                        sit over your website as a pill.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/report-bugs.svg"
                        alt="adding tickets icon"
                        title="adding tickets icon"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Report Bugs</h3>
                      <p>
                        Activate the pill and start reporting bugs directly on
                        the website. It&apos;s like commenting on Figma but on
                        live product.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="card-single">
                  <div className="card-block">
                    <div className="card-icon">
                      <img
                        loading="lazy"
                        src="/assets/img/track-progress.svg"
                        alt="manage with workflow icon"
                        title="manage with workflow icon"
                      />
                    </div>
                    <div className="card-desc">
                      <h3>Track Progress</h3>
                      <p>
                        These comments will be added as tickets on the ruttl
                        dashboard. You can prioritise, assign and set dates
                        similar to Jira.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="center">
              <button
                className="button"
                type="button"
                onClick={toggleVideoModal}>
                See demo video
              </button>
            </div>
          </div>
        </section>
      </main>
      <section className="section-pad">
        <div className="container " style={{ marginTop: 0 }}>
          <div className="flex-row">
            <div className="col col-8 offset-2">
              <div className="feedback bug-track-feedback">
                <div className="feebackImage">
                  <img
                    loading="lazy"
                    src="/assets/img/atlassian-logo-round.png"
                    alt="Atlassian Logo"
                    title="Atlassian Logo"
                  />
                </div>
                <div style={{ maxWidth: 560 }}>
                  <h3>
                    We loved using ruttl. The entire process right from sign-up
                    to commenting & managing feedback is so smooth.
                  </h3>
                  <p>
                    <span>-Josh Shepherd,&nbsp;</span>
                    Event Tech Strategy at Atlassian
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="usecases-styled-main" id="features">
        <div className="section features-main bugtrack-features">
          <div className="container">
            <div className="section-head">
              <h2 className="center mx-auto" style={{ maxWidth: 700 }}>
                No more screenshots or clumsy excel sheets to track bugs
              </h2>
            </div>

            <FeatureContainer>
              <div className="feature-set-single">
                <div className="flex flex-wrap align-items-center ">
                  <div className="feature-img">
                    <div className="feature-img-bg">
                      <img
                        loading="lazy"
                        src="/assets/img/report-bug.png"
                        alt="report-bug"
                        title="report-bug"
                      />
                    </div>
                  </div>
                  <div className="feature-txt">
                    <h3 className="feature-head">Report issues fast</h3>
                    <p>
                      Report contextual bugs as comments directly on your
                      product. These appear as tickets on the ruttl dashboard.
                    </p>
                  </div>
                </div>
              </div>
            </FeatureContainer>
            <FeatureContainer>
              <div className="feature-set-single">
                <div className="flex flex-wrap align-items-center ">
                  <div className="feature-img">
                    <div className="feature-img-bg">
                      <img
                        loading="lazy"
                        src="/assets/img/track-progress.png"
                        alt="track-progress"
                        title="track-progress"
                      />
                    </div>
                  </div>
                  <div className="feature-txt">
                    <h3 className="feature-head">
                      Solve them faster with workflow
                    </h3>
                    <p>
                      Capture, assign, prioritize, and resolve bugs seamlessly
                      and collaboratively. Instead of workflow, you can also
                      integrate ruttl with tools like Jira, Trello, asana and
                      many more.
                    </p>
                  </div>
                </div>
              </div>
            </FeatureContainer>
            <FeatureContainer>
              <div className="feature-set-single">
                <div className="flex flex-wrap align-items-center ">
                  <div className="feature-img">
                    <div className="feature-img-bg">
                      <img
                        loading="lazy"
                        src="/assets/img/collab-with-team.png"
                        alt="collab-with-teams"
                        title="collab-with-teams"
                      />
                    </div>
                  </div>
                  <div className="feature-txt">
                    <h3 className="feature-head">
                      Save time and work smarter with your team
                    </h3>
                    <p>
                      Each member of your team will get notified about these
                      bugs and you can then assign tasks, set deadlines and
                      prioritize them as per the need. Jira with fewer steps.
                    </p>
                  </div>
                </div>
              </div>
            </FeatureContainer>

            <FeatureContainer>
              <div className="feature-set-single">
                <div className="flex flex-wrap align-items-center ">
                  <div className="feature-img">
                    <div className="feature-img-bg">
                      <img
                        loading="lazy"
                        src="/assets/img/report-bugs-app.png"
                        alt="report-bug"
                        title="report-bug"
                      />
                    </div>
                  </div>
                  <div className="feature-txt">
                    <h3 className="feature-head">
                      Report bugs directly on mobile apps
                    </h3>
                    <p>
                      Collect bugs directly on your mobile app in the form of
                      comments so you do not lose the context. The comments will
                      get added in form of tickets to your dashboard.
                    </p>
                  </div>
                </div>
              </div>
            </FeatureContainer>
            <FeatureContainer>
              <div className="feature-set-single">
                <div className="flex flex-wrap align-items-center ">
                  <div className="feature-img">
                    <div className="feature-img-bg">
                      <img
                        loading="lazy"
                        src="/assets/img/integration-tools.png"
                        alt="integrate with needed tools"
                        title="integrate with needed tools"
                      />
                    </div>
                  </div>
                  <div className="feature-txt">
                    <h3 className="feature-head">
                      Integrated with needed tools
                    </h3>
                    <p>
                      Receive notifications for new tickets through your
                      preferred tools such as Slack, Trello, Asana, ClickUp,
                      Jira or Zapier.
                    </p>
                  </div>
                </div>
              </div>
            </FeatureContainer>
          </div>
        </div>

        <FeaturesGrid
          data={BugTrackingFeatures}
          productType="bug-tracking-tool"
        />
        <TestimonialsSlider />

        <Reviews />
        <NewFaq data={faqDatabugTracking} />
      </section>
    </Layout>
  );
};

BugTracking.propTypes = {
  location: PropTypes.object,
};

export default BugTracking;
